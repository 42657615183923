<template>
  <div class="tram-order-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>
<script>
  import cabinetUtil from "@utils/cabinetUtil.js"
  export default {
    data() {
      let _this = this;
      return {
        dialogVisible: false,
        opt: {
          title: "订单列表",
          search: [
            {
              key: "orderId",
              label: "订单编号",
            },
            {
              key: "merchantId",
              label: "商家名称",
              type: 'remoteSearchById',
              opt: {
                list: [],
              }
            },
            {
              key: "date",
              label: "支付时间",
              type: "data-range"
            },
            {
              type: 'btnGroup',
              key: "btnGroup",
              buts: [{
                  type: "primary",
                  label: "今日",
                  timeType: 'day'
                },
                {
                  type: "primary",
                  label: "最近一周",
                  timeType: 'lastWeek'
                },
                {
                  type: "primary",
                  label: "本月",
                  timeType: 'month'
                },
                {
                  type: "primary",
                  label: "上月",
                  timeType: 'lastMonth'
                }
              ]
            }
          ],
          columns: [{
              label: "订单编号",
              key: "id",
            },
            {
              label: "商家名称",
              key: "merchantName",
            },
            {
              label: "商品名称",
              key: "bcItemName",
            },
            {
              label: "用户账号",
              key: "userName",
            },
            {
              label: "手机号码",
              key: "mobile",
            }, 
            {
              label: "支付金额",
              key: "realAmount",
            },
            {
              label: "数量",
              key: "num",
            },
            {
              label: "支付方式",
              key: "payMethodStr",
            },
            {
              label: "支付时间",
              key: "realPayTime",
            },
            {
              label: "支付状态",
              key: "state",
            },
          ],
          // 这一期先不做
          // exportButtons: [{
          //   type: "Export",
          //   url: this.EXport + "/mall-service/orders/export",
          //   listName: '售货订单'
          // }],
        },
        merchantFlag: false,
      };
    },
    created() {
      console.log("salesOrder-list created!!");

    },
    async activated() {
      console.log("salesOrder-list activated!!");
      this.opt.search.forEach(item => {
        if (item.key == "btnGroup") {
          item.buts.forEach(it => {
            it.type = "primary";
          })
        }
      })
      try {
          this.merchantFlag = this.getLoginType('loginType');
          if (this.merchantFlag) {
            this.opt.search.forEach((item, index) => {
              if (item.key == 'merchantId') {
                this.opt.search.splice(index, 1);
              }
            })
            this.opt.columns.forEach((item, index) => {
              if (item.key == 'merchantName') {
                this.opt.columns.splice(index, 1);
              }
            })
          } else {
            let merchantList = await cabinetUtil.getMerchantList();
            this.opt.search.forEach((item, index) => {
              if (item.key == 'merchantId') {
                item.opt.list = merchantList;
              }
            })
          }
        } catch (error) {
          console.log(error)
        }
    },

    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          orderType: 1,
          state: 3,//只需要支付成功的订单
          ...opt.searchForm,
          startTime: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
          endTime: opt.searchForm['date'] ? moment(opt.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") :
            null,
        };
        this.post("mall-service/bc/order/page", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          res.data.list.forEach(item => {
            if (item.payMethod == 1) {
              item.payMethodStr = "微信";
            } else if (item.payMethod == 2) {
              item.payMethodStr = "支付宝";
            }
            item.state = ['新建支付中','支付中','支付成功','支付失败','订单取消','支付超时'][item.state - 1]
            item.realAmount = item.realAmount ? Number(item.realAmount).toFixed(2) : '--';
          });
          opt.cb(res.data);
        });
      },
    }
  };
</script>
<style lang="scss" scoped>
  /deep/.el-table td:last-child {
    padding-right: 16px
  }
</style>